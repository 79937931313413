class ProductResults extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    // Add event listener to button
    const viewAllProducts = this.querySelector('#viewAllProducts');
    console.log('viewAllProducts', viewAllProducts);
    if (viewAllProducts) {
      viewAllProducts.addEventListener('click', () => {
        const hiddenProducts = this.querySelectorAll('.hidden-product');
        hiddenProducts.forEach((product) => {
          product.classList.remove('hidden-product');
        });
        viewAllProducts.style.display = 'none'; // Hide button after use
      });
    }
  }
}

customElements.define('product-results', ProductResults);
